import React from 'react';
import VotingAndMenu from './votingAndMenu';
import PostContent from './postContent';
import Quiz from './quiz';
import 'react-tooltip/dist/react-tooltip.css';

// SocialMediaPost Component
const SocialMediaPost = ({ postData, clickedWords, onWordClick, onVote, voteStatus, showQuiz = false }) => {
    return (
        <div className="bg-white rounded-lg shadow-lg p-6 mb-6 transition-transform transform hover:scale-105">
            <VotingAndMenu votes={postData.votes} onVote={onVote} voteStatus={voteStatus} level={postData.level} />
            <PostContent postData={postData} clickedWords={clickedWords} onWordClick={onWordClick} />
            {showQuiz && <Quiz quizData={postData} />}
        </div>
    );
};

export default SocialMediaPost;

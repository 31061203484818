// PrivateRoute.js
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../contexts/authContext';
import CircularProgress from '@mui/material/CircularProgress';

function PrivateRoute({ children }) {
    const { user, loading } = useContext(AuthContext);

    if (loading) {
        return <CircularProgress />; 
    }

    return user ? children : <Navigate to="/login" />;
}

export default PrivateRoute;

import React, { useState, useEffect, useContext } from 'react';
import SocialMediaPost from './socialMediaPost';
import { AuthContext } from '../contexts/authContext';

const SocialMediaFeed = () => {
    const [postData, setPostData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [clickedWords, setClickedWords] = useState(new Set());
    const [voteStatus, setVoteStatus] = useState(0);

    const { user } = useContext(AuthContext);

    const fetchPostData = async () => {
        try {
            const response = await fetch(`/api/posts/`, {
                headers: {
                    'Authorization': `Bearer ${user.access_token}`
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setPostData(data[0]);
            setVoteStatus(0); // Reset vote status for new post
            setLoading(false);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPostData();
    }, []);

    const handleWordClick = (position) => {
        setClickedWords(prev => new Set(prev).add(position));
    };

    const handleVote = (newVoteStatus) => {
        if (newVoteStatus === voteStatus) {
            setVoteStatus(0); // Toggle off if clicking the same vote
        } else {
            setVoteStatus(newVoteStatus);
        }
    };

    const showNextPost = async () => {
        if (postData) {
            try {
                const vocabularyStatus = postData.vocabulary.map(word => ({
                    word: word.base_word,
                    revealed: clickedWords.has(word.position)
                }));

                const postHistory = {
                    user_id: null,
                    post_id: postData._id,
                    vocabulary_status: vocabularyStatus,
                    vote_status: voteStatus
                };

                await fetch('/api/posts/history/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.access_token}`
                    },
                    body: JSON.stringify(postHistory)
                });

                // Reset states and fetch next post
                setClickedWords(new Set());
                setVoteStatus(0);
                setLoading(true);
                fetchPostData();
            } catch (err) {
                console.error('Failed to send post history:', err);
            }
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="container mx-auto p-4">
            <div className="space-y-6">
                {postData ? (
                    <SocialMediaPost 
                        postData={postData} 
                        clickedWords={clickedWords}
                        onWordClick={handleWordClick}
                        onVote={handleVote}
                        voteStatus={voteStatus}
                    />
                ) : (
                    <div>No posts available.</div>
                )}
                <button
                    onClick={showNextPost}
                    className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors"
                >
                    Show Next Post
                </button>
            </div>
        </div>
    );
};

export default SocialMediaFeed;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import HomePage from './pages/homePage';
import ProfilePage from './pages/profilePage';
import NotFoundPage from './pages/notFoundPage';
import LoginPage from './pages/loginPage';
import RegisterPage from './pages/registerPage';
import PrivateRoute from './components/privateRoute';
import Feed from './pages/feedPage';
import DashboardPage from './pages/dashboardPage';
import { AuthProvider } from './contexts/authContext';
import InsightsPage from './pages/insightsPage';



function App() {
  return (
    
      <AuthProvider>
        <Router>
          <div className="App">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/home" element={<HomePage />} />
              <Route path="/dashboard" element={<PrivateRoute><DashboardPage /></PrivateRoute>} />
              <Route path="/feed" element={<PrivateRoute><Feed /></PrivateRoute>} />
              <Route path="/profile" element={<PrivateRoute><ProfilePage /></PrivateRoute>} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/insights" element={<InsightsPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
        </Router>
      </AuthProvider>
    
  );
}

export default App;

import React, { useState } from 'react';
import { 
  Typography, Table, TableBody, TableCell, TableContainer, TableHead, 
  TableRow, Paper, TableSortLabel, Card, CardContent, Pagination
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme, isLastColumn }) => ({
  '&.MuiTableCell-head': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: '8px 16px',
    borderRight: isLastColumn ? 'none' : `1px solid ${theme.palette.common.white}`,
  },
  '&.MuiTableCell-body': {
    fontSize: 14,
    padding: '8px 16px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function InsightsTable({ 
  data, 
  title, 
  columns, 
  initialSortColumn, 
  initialSortOrder = 'desc',
  itemsPerPage = 10
}) {
  const [page, setPage] = useState(1);
  const [sortCriteria, setSortCriteria] = useState([{ column: initialSortColumn, order: initialSortOrder }]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRequestSort = (property) => {
    const isAsc = sortCriteria[0]?.column === property && sortCriteria[0]?.order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    
    setSortCriteria(prevCriteria => {
      const newCriteria = prevCriteria.filter(c => c.column !== property);
      return [{ column: property, order: newOrder }, ...newCriteria];
    });
  };

  const sortedData = React.useMemo(() => {
    return [...data].sort((a, b) => {
      for (const { column, order } of sortCriteria) {
        if (a[column] !== b[column]) {
          if (typeof a[column] === 'string') {
            return order === 'asc' 
              ? a[column].localeCompare(b[column]) 
              : b[column].localeCompare(a[column]);
          }
          return order === 'asc' ? a[column] - b[column] : b[column] - a[column];
        }
      }
      return 0;
    });
  }, [data, sortCriteria]);

  const paginatedData = sortedData.slice(
    (page - 1) * itemsPerPage, 
    page * itemsPerPage
  );

  const formatCellValue = (value, column) => {
    if (column.isPercentage) {
      return `${(value * 100).toFixed(2)}%`;
    }
    return value;
  };

  return (
    <Card elevation={3} className="overflow-hidden mb-6">
      <CardContent className="p-0">
        <Typography variant="h6" className="p-4 bg-gray-100">{title}</Typography>
        <TableContainer component={Paper} elevation={0}>
          <Table size="small" aria-label={`${title.toLowerCase()} table`}>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <StyledTableCell 
                    key={column.key} 
                    isLastColumn={index === columns.length - 1}
                    align={column.align || 'left'}
                  >
                    {column.sortable ? (
                      <TableSortLabel
                        active={sortCriteria.some(c => c.column === column.key)}
                        direction={sortCriteria.find(c => c.column === column.key)?.order || 'asc'}
                        onClick={() => handleRequestSort(column.key)}
                      >
                        {column.label}
                      </TableSortLabel>
                    ) : (
                      column.label
                    )}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.map((row, index) => (
                <StyledTableRow key={index} className="hover:bg-gray-50 transition-colors duration-150">
                  {columns.map((column) => (
                    <StyledTableCell key={column.key} align={column.align || 'left'}>
                      {formatCellValue(row[column.key], column)}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="flex justify-center mt-4 p-2">
          <Pagination
            count={Math.ceil(data.length / itemsPerPage)}
            page={page}
            onChange={handleChangePage}
            color="primary"
            size="small"
            className="pagination"
          />
        </div>
      </CardContent>
    </Card>
  );
}

export default InsightsTable;
import axios from 'axios';


const register = async (user_to_register) => {
    const response = await axios.post(`/api/users/register`, 
        user_to_register
);
    return response.data;
};

const login = async (username, password) => {
    const response = await axios.post(`/api/users/login`, {
        username,
        password
    });
    if (response.data.access_token) {
        localStorage.setItem('user', JSON.stringify(response.data));
    }
    return response.data;
};


const logout = () => {
    localStorage.removeItem('user');
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem('user'));
};

const authService = {
    register,
    login,
    logout,
    getCurrentUser
};

export default authService;

// src/components/phoneInputWidget.js
import React from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

const PhoneInputWidget = ({ value, onChange }) => (
    <div className="py-2">
        <PhoneInput
            placeholder="Enter phone number"
            value={value}
            onChange={onChange}
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
        />
    </div>
);

export default PhoneInputWidget;

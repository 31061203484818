import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/authContext';
import BasePage from '../components/basePage';
import FormInput from '../components/formInput';
import PhoneInputWidget from '../components/phoneInputWidget';
import { MenuItem, Select, InputLabel, FormControl, FormHelperText, IconButton, InputAdornment, CircularProgress } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const CEFR_LEVELS = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'];
const SUPPORTED_PREFERRED_LANGUAGES = ['English', 'Spanish', 'French', 'German', 'Chinese', 'Japanese', 'Korean', 'Italian', 'Russian', 'Arabic'];
const SUPPORTED_TARGET_LANGUAGES = ['German'];

const RegisterPage = () => {
    const [formData, setFormData] = useState({
        name: '',
        username: '',
        email: '',
        phoneNumber: '',
        preferredLanguage: 'English',
        targetLanguage: 'German',
        currentTargetLanguageLevel: '',
        password: '',
        confirmPassword: ''
    });

    const navigator = useNavigate();
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false); 
    const { register } = useContext(AuthContext);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const handlePhoneChange = (value) => {
        setFormData({ ...formData, phoneNumber: value });
        setErrors({ ...errors, phoneNumber: '' });
    };

    const handleClickShowPassword = () => setShowPassword((prev) => !prev);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((prev) => !prev);

    const validatePassword = (password) => {
        const errors = [];
        if (password.length < 8) {
            errors.push('Password must be at least 8 characters long.');
        }
        if (!/[A-Z]/.test(password)) {
            errors.push('Password must contain at least one uppercase letter.');
        }
        if (!/[a-z]/.test(password)) {
            errors.push('Password must contain at least one lowercase letter.');
        }
        if (!/[0-9]/.test(password)) {
            errors.push('Password must contain at least one number.');
        }
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
            errors.push('Password must contain at least one special character.');
        }
        return errors;
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.email && !formData.phoneNumber) {
            newErrors.email = 'Either email or phone number is required.';
            newErrors.phoneNumber = 'Either email or phone number is required.';
        }
        if (!formData.name) newErrors.name = 'Name is required.';
        if (!formData.username) newErrors.username = 'Username is required.';
        if (!formData.preferredLanguage) newErrors.preferredLanguage = 'Preferred language is required.';
        if (!formData.targetLanguage) newErrors.targetLanguage = 'Target language is required.';
        if (!formData.currentTargetLanguageLevel) newErrors.currentTargetLanguageLevel = 'Current target language level is required.';
        if (!formData.password) {
            newErrors.password = 'Password is required.';
        } else {
            const passwordErrors = validatePassword(formData.password);
            if (passwordErrors.length > 0) {
                newErrors.password = passwordErrors.join(' ');
            }
        }
        if (formData.password !== formData.confirmPassword) {
            newErrors.confirmPassword = 'Passwords do not match.';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        setIsLoading(true); 
        const result = await register(formData);
        
        setIsLoading(false);

        if (result.success) {
           navigator('/');
        } else {
            const newErrors = {};
            if (result.error.includes('Email already registered')) {
                newErrors.email = 'Email is already registered.';
            } else if (result.error.includes('Username already registered')) {
                newErrors.username = 'Username is already registered.';
            } else {
                alert(result.error);
            }
            setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));
        }
    };

    return (
        <BasePage>
            <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 py-12 px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8">
                    <div>
                        <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Create your account</h1>
                        <p className="mt-2 text-center text-sm text-gray-600">
                            Please fill in the details below. Fields marked with <span className="text-red-500">*</span> are required.
                        </p>
                        <p className="mt-2 text-center text-sm text-gray-500">Either email or phone number is required</p>
                    </div>
                    <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                        <FormInput
                            id="name"
                            name="name"
                            type="text"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Name (e.g., John Doe)"
                            error={!!errors.name}
                            helperText={errors.name}
                        />
                        <FormInput
                            id="username"
                            name="username"
                            type="text"
                            value={formData.username}
                            onChange={handleChange}
                            placeholder="Username (e.g., johndoe123)"
                            error={!!errors.username}
                            helperText={errors.username}
                        />
                        <FormInput
                            id="email"
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Email (e.g., johndoe@example.com)"
                            error={!!errors.email}
                            helperText={errors.email}
                        />
                        <PhoneInputWidget
                            value={formData.phoneNumber}
                            onChange={handlePhoneChange}
                            error={!!errors.phoneNumber}
                            helperText={errors.phoneNumber}
                        />
                        <FormControl fullWidth error={!!errors.preferredLanguage}>
                            <InputLabel id="preferred-language-label">Preferred Language</InputLabel>
                            <Select
                                labelId="preferred-language-label"
                                id="preferredLanguage"
                                name="preferredLanguage"
                                value={formData.preferredLanguage}
                                label="Preferred Language"
                                onChange={handleChange}
                            >
                                {SUPPORTED_PREFERRED_LANGUAGES.map((language) => (
                                    <MenuItem key={language} value={language}>
                                        {language}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.preferredLanguage && <FormHelperText>{errors.preferredLanguage}</FormHelperText>}
                        </FormControl>
                        <FormControl fullWidth error={!!errors.targetLanguage}>
                            <InputLabel id="target-language-label">Target Language</InputLabel>
                            <Select
                                labelId="target-language-label"
                                id="targetLanguage"
                                name="targetLanguage"
                                value={formData.targetLanguage}
                                label="Target Language"
                                onChange={handleChange}
                            >
                                {SUPPORTED_TARGET_LANGUAGES.map((language) => (
                                    <MenuItem key={language} value={language}>
                                        {language}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.targetLanguage && <FormHelperText>{errors.targetLanguage}</FormHelperText>}
                        </FormControl>
                        <FormControl fullWidth error={!!errors.currentTargetLanguageLevel}>
                            <InputLabel id="cefr-level-label">Current Target Language Level</InputLabel>
                            <Select
                                labelId="cefr-level-label"
                                id="currentTargetLanguageLevel"
                                name="currentTargetLanguageLevel"
                                value={formData.currentTargetLanguageLevel}
                                label="Current Target Language Level"
                                onChange={handleChange}
                            >
                                {CEFR_LEVELS.map((level) => (
                                    <MenuItem key={level} value={level}>
                                        {level}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.currentTargetLanguageLevel && <FormHelperText>{errors.currentTargetLanguageLevel}</FormHelperText>}
                        </FormControl>
                        <FormInput
                            id="password"
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            value={formData.password}
                            onChange={handleChange}
                            placeholder="Password"
                            error={!!errors.password}
                            helperText={errors.password}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <FormInput
                            id="confirmPassword"
                            name="confirmPassword"
                            type={showConfirmPassword ? 'text' : 'password'}
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            placeholder="Confirm Password"
                            error={!!errors.confirmPassword}
                            helperText={errors.confirmPassword}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowConfirmPassword}
                                        edge="end"
                                    >
                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <div>
                            <button
                                type="submit"
                                disabled={isLoading}
                                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                {isLoading ? <CircularProgress size={24} /> : 'Register'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </BasePage>
    );
};

export default RegisterPage;

import React from 'react';
import Header from './header';
import Footer from './footer';
import './basePage.css'; // Assuming some common CSS for the base page

const BasePage = ({ children }) => {
    return (
        <div className="base-page">
            <Header/>
            <div className="content">
                {children}
            </div>
            <Footer />
        </div>
    );
};

export default BasePage;

import React from 'react';

function Footer() {
  return (
    <footer className="w-full bg-gray-800 p-4 text-center text-gray-300">
      <p>© 2024 LinguagenAI Simulated Social. All rights reserved.</p>
    </footer>
  );
}

export default Footer;

import React, { useState, useEffect, useContext } from 'react';
import { Typography, CircularProgress, Card, CardContent, Tabs, Tab } from '@mui/material';
import BasePage from '../components/basePage';
import { AuthContext } from '../contexts/authContext';
import InsightsTable from '../components/InsightsTable';

function InsightsPage() {
  const [insights, setInsights] = useState(null);
  const [vocabularyInsights, setVocabularyInsights] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user && user.access_token) {
      const fetchInsights = async () => {
        try {
          const [insightsResponse, vocabularyResponse] = await Promise.all([
            fetch('/api/insights/', {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.access_token}`
              },
            }),
            fetch('/api/insights/vocabulary-insights/', {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.access_token}`
              },
            })
          ]);

          if (!insightsResponse.ok || !vocabularyResponse.ok) {
            throw new Error(`HTTP error! status: ${insightsResponse.status} or ${vocabularyResponse.status}`);
          }

          const [insightsData, vocabularyData] = await Promise.all([
            insightsResponse.json(),
            vocabularyResponse.json()
          ]);

          setInsights(insightsData);
          setVocabularyInsights(vocabularyData);
          setLoading(false);
        } catch (e) {
          console.error("An error occurred while fetching insights:", e);
          setError("Failed to load insights. Please try again later.");
          setLoading(false);
        }
      };
      fetchInsights();
    }
  }, [user]);

  const wordHistoryColumns = [
    { key: 'word', label: 'Word', sortable: true },
    { key: 'total_count', label: 'Total Count', sortable: true, align: 'right' },
    { key: 'revealed_count', label: 'Revealed Count', sortable: true, align: 'right' },
  ];

  const vocabularyColumns = [
    { key: 'word', label: 'Word', sortable: true },
    { key: 'total_count', label: 'Total Count', sortable: true, align: 'right' },
    { key: 'revealed_count', label: 'Revealed Count', sortable: true, align: 'right' },
    { key: 'confidence', label: 'Confidence', sortable: true, align: 'right', isPercentage: true },
  ];

  return (
    <BasePage>
      <div className="container mx-auto px-4 py-8">
        <Typography variant="h4" component="h1" className="mb-6 font-bold text-primary">
          Your Insights
        </Typography>
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <CircularProgress />
          </div>
        ) : error ? (
          <Card className="bg-red-50 border border-red-200">
            <CardContent>
              <Typography color="error">{error}</Typography>
            </CardContent>
          </Card>
        ) : (
          <>
            <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)} className="mb-4">
              <Tab label="Word History" />
              <Tab label="Known Words" />
              <Tab label="Unknown Words" />
            </Tabs>
            {activeTab === 0 && (
              <InsightsTable
                data={insights?.word_stats || []}
                title="Word History"
                columns={wordHistoryColumns}
                initialSortColumn="total_count"
                itemsPerPage={10}
              />
            )}
            {activeTab === 1 && (
              <InsightsTable
                data={vocabularyInsights?.user_known_words || []}
                title="Known Words"
                columns={vocabularyColumns}
                initialSortColumn="word"
                itemsPerPage={10}
              />
            )}
            {activeTab === 2 && (
              <InsightsTable
                data={vocabularyInsights?.user_unknown_words || []}
                title="Unknown Words"
                columns={vocabularyColumns}
                initialSortColumn="word"
                itemsPerPage={10}
              />
            )}
          </>
        )}
      </div>
    </BasePage>
  );
}

export default InsightsPage;
import React from 'react';
import BasePage from '../components/basePage';

function ProfilePage() {
  return (
    <BasePage>
      <div>
        <h2>Profile Page</h2>
        {/* Profile content to be added here */}
      </div>
    </BasePage>
  );
}

export default ProfilePage;

import React from 'react';
import { Tooltip, Typography } from '@mui/material';

const WordWithTooltip = ({ word, translation, explanation, position, isActive, isRevealed, onClick }) => {
    return (
        <Tooltip
            title={
                <Typography variant="body2">
                    <strong>Translation:</strong> {translation}<br />
                    <strong>Explanation:</strong> {explanation}
                </Typography>
            }
            open={isActive}
            disableFocusListener
            disableHoverListener
            disableTouchListener
        >
            <span
                onClick={() => onClick(position)}
                className={`word-tooltip cursor-pointer px-1 py-0.5 rounded transition-all duration-200 inline-block mx-0.5
                    ${isRevealed ? 'highlighted border border-green-500 text-green-600' : 'border border-transparent'}`}
            >
                {word}
            </span>
        </Tooltip>
    );
};

export default WordWithTooltip;

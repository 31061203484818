import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faStar } from '@fortawesome/free-solid-svg-icons';

// Updated level mapping dictionary with colors
const levelMapping = {
    "A1/A2": { name: "Beginner", color: "bg-green-100 text-green-700" },
    'beginner_low': { name: 'Novice', color: "bg-green-100 text-green-700" },
    'beginner_high': { name: 'Beginner', color: "bg-green-100 text-green-700" },
    'intermediate_low': { name: 'Intermediate', color: "bg-yellow-100 text-yellow-700" },
    'intermediate_high': { name: 'Advanced', color: "bg-orange-100 text-orange-700" },
    'advanced_low': { name: 'Expert', color: "bg-red-100 text-red-700" },
    'advanced_high': { name: 'Master', color: "bg-purple-100 text-purple-700" }
};

// VotingAndMenu Component
const VotingAndMenu = ({ votes, onVote, voteStatus, level }) => {
    // Calculate displayed votes based on current voteStatus
    const displayedVotes = votes + (voteStatus === 1 ? 1 : voteStatus === -1 ? -1 : 0);

    // Updated function to get user-friendly level name and color
    const getUserFriendlyLevel = (level) => {
        return levelMapping[level] || { name: level, color: "bg-blue-100 text-blue-700" }; // Fallback to blue if not found
    };

    const { name: friendlyLevel, color: levelColor } = getUserFriendlyLevel(level);

    return (
        <div className="flex justify-between items-center mb-4">
            <div className="flex items-center space-x-2">
                <button 
                    onClick={() => onVote(1)}
                    className={`text-sm hover:underline focus:outline-none ${voteStatus === 1 ? 'text-green-600' : 'text-green-500'}`}
                >
                    <FontAwesomeIcon icon={faArrowUp} className="w-4 h-4" />
                </button>
                <span className="text-gray-500 text-xs">{displayedVotes}</span>
                <button 
                    onClick={() => onVote(-1)}
                    className={`text-sm hover:underline focus:outline-none ${voteStatus === -1 ? 'text-red-600' : 'text-red-500'}`}
                >
                    <FontAwesomeIcon icon={faArrowDown} className="w-4 h-4" />
                </button>
            </div>
            <div className={`flex items-center rounded-full px-3 py-1 ${levelColor}`}>
                <FontAwesomeIcon icon={faStar} className="w-3 h-3 mr-1" />
                <span className="text-xs font-semibold">{friendlyLevel}</span>
            </div>
            <button className="text-gray-500 text-sm hover:underline focus:outline-none">
                <img src="https://placehold.co/20" alt="Menu" className="w-4 h-4" />
            </button>
        </div>
    );
};

export default VotingAndMenu;

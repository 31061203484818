import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../contexts/authContext';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <header className="flex items-center bg-indigo-600 text-white px-8 py-4 shadow-md">
      <FontAwesomeIcon icon={faLanguage} className="mr-3 text-2xl" />
      <h1 className="text-2xl font-semibold">LinguagenAI Simulated Social</h1>
      <div className="flex-grow text-right">
        {user ? (
          <>
            <button 
              onClick={logout} 
              className="px-4 py-2 bg-indigo-500 rounded-md hover:bg-indigo-700 ml-2"
            >
              Logout
            </button>
            <button 
              onClick={() => navigate('/profile')} 
              className="px-4 py-2 bg-indigo-500 rounded-md hover:bg-indigo-700 ml-2"
            >
              Profile
            </button>
            <button 
              onClick={() => navigate('/dashboard')} 
              className="px-4 py-2 bg-indigo-500 rounded-md hover:bg-indigo-700 ml-2"
            >
              Dashboard
            </button>
          </>
        ) : (
          <a 
            href="/login" 
            className="px-4 py-2 bg-indigo-500 rounded-md hover:bg-indigo-700 ml-2"
          >
            Login
          </a>
        )}
      </div>
    </header>
  );
};

export default Header;

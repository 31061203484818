import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Grid, Paper, Button, Container } from '@mui/material';
import BasePage from '../components/basePage';

function DashboardPage() {
  return (
    <BasePage>
      <Container maxWidth="lg" className="py-8">
        <Typography variant="h4" component="h1" gutterBottom className="text-primary mb-6">
          Welcome to Your Dashboard
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={2} className="p-6 h-full transition duration-300 ease-in-out hover:shadow-md">
              <Typography variant="h6" component="h2" gutterBottom className="text-secondary">
                Practice
              </Typography>
              <Typography variant="body2" className="text-gray-600 mb-4">
                Improve your skills with our simulated social media environment.
              </Typography>
              <Button
                component={Link}
                to="/feed"
                variant="contained"
                color="primary"
                fullWidth
                className="mt-auto"
              >
                Open Social Media Feed
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={2} className="p-6 h-full transition duration-300 ease-in-out hover:shadow-md">
              <Typography variant="h6" component="h2" gutterBottom className="text-secondary">
                Insights
              </Typography>
              <Typography variant="body2" className="text-gray-600 mb-4">
                Track your progress and view detailed analytics of your performance.
              </Typography>
              <Button
                component={Link}
                to="/insights"
                variant="contained"
                color="primary"
                fullWidth
                className="mt-auto"
              >
                View Insights
              </Button>
            </Paper>
          </Grid>
          {/* Add more Grid items for additional sections */}
        </Grid>
      </Container>
    </BasePage>
  );
}

export default DashboardPage;
import React, { useState } from 'react';
import WordWithTooltip from './wordWithTooltip';

// PostContent Component
const PostContent = ({ postData, clickedWords, onWordClick }) => {
    const [latestShown, setLatestShown] = useState(null);

    const onWordClickShow = function(position) {
        if (position === latestShown) {
            setLatestShown(null);
        }else {
            setLatestShown(position);
            onWordClick(position);
        }
        
        
    }
    return (
        <>
            <div className="flex items-center mb-4">
                <img src={postData.author.avatar} alt="Author" className="w-8 h-8 rounded-full border-2 border-gray-300" />
                <div className="ml-2">
                    <span className="font-bold text-gray-800">{postData.author.name}</span>
                    <span className="text-gray-500 text-xs"> - {postData.timeAgo}</span>
                </div>
            </div>

            <div className="mb-4">
                <div className="p-4 bg-gray-50 border border-gray-200 rounded">
                    <p className="text-gray-800" style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
                        {postData.vocabulary.map((word) => (
                            <WordWithTooltip
                                key={word.position}
                                word={word.word}
                                translation={word.translation}
                                explanation={word.explanation}
                                position={word.position}
                                isActive={word.position === latestShown}
                                isRevealed = {clickedWords.has(word.position)}
                                onClick={onWordClickShow}
                            />
                        ))}
                    </p>
                </div>
                <div className="flex items-center space-x-2 mt-4">
                    <span className="text-gray-500 text-xs">Fully understand</span>
                    <input
                        type="range"
                        min="1"
                        max="5"
                        defaultValue="3"
                        className="w-full slider h-2"
                        step="1"
                    />
                    <span className="text-gray-500 text-xs">Don't understand</span>
                </div>
            </div>
        </>
    );
};

export default PostContent;

// src/components/formInput.js
import React from 'react';

const FormInput = ({ id, name, type, value, onChange, placeholder, required = true, error, helperText, endAdornment }) => (
    <div className="py-2">
        <label htmlFor={id} className="sr-only">{name}</label>
        <div className="relative">
            <input 
                id={id} 
                name={name} 
                type={type} 
                value={value}
                onChange={onChange} 
                required={required} 
                className={`appearance-none rounded-none relative block w-full px-3 py-2 border ${error ? 'border-red-500' : 'border-gray-300'} placeholder-gray-500 text-gray-900 focus:outline-none ${error ? 'focus:ring-red-500 focus:border-red-500' : 'focus:ring-indigo-500 focus:border-indigo-500'} focus:z-10 sm:text-sm`} 
                placeholder={placeholder} 
            />
            {endAdornment && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                    {endAdornment}
                </div>
            )}
        </div>
        {helperText && <p className="mt-1 text-sm text-red-600">{helperText}</p>}
    </div>
);

export default FormInput;


import React from 'react';
import BasePage from '../components/basePage';

function NotFoundPage() {
  return (
    <BasePage>
      <div>
        <h2>404 Not Found</h2>
      </div>
    </BasePage>
  );
}

export default NotFoundPage;

// src/pages/homePage.js
import React from 'react';
import BasePage from '../components/basePage';
import { Link } from 'react-router-dom';

function HomePage() {
  return (
    <BasePage>
      <div className="max-w-4xl mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">Welcome to LinguaGenAI</h1>
        <p className="text-xl text-gray-600 mb-8">Learn German with AI-simulated social media</p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">AI-Generated Posts</h2>
            <p className="text-gray-600 mb-4">Read current news and posts in simple German, created by AI agents to help you learn.</p>
            <Link to="/feed" className="inline-block bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors">Go to Feed</Link>
          </div>
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Interactive Learning</h2>
            <p className="text-gray-600 mb-4">Interact with AI agents to improve your German and test your understanding.</p>
            <Link to="/dashboard" className="inline-block bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors">Start Practicing</Link>
          </div>
        </div>

        <div className="bg-gray-100 rounded-lg p-6 mb-12">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Why LinguagenAI?</h2>
          <ul className="list-disc list-inside text-gray-600">
            <li>Real-time content generation on current topics</li>
            <li>Adjustable difficulty levels for your learning progress</li>
            <li>Interactive exercises to improve your comprehension</li>
            <li>Simulated social media environment for an immersive learning experience</li>
          </ul>
        </div>

        <div className="text-center">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Ready to start learning?</h2>
          <Link to="/register" className="inline-block bg-indigo-500 text-white px-6 py-3 rounded-lg text-lg font-semibold hover:bg-indigo-600 transition-colors">Sign up for free</Link>
        </div>
      </div>
    </BasePage>
  );
}

export default HomePage;

// src/pages/homePage.js
import React from 'react';
import BasePage from '../components/basePage';
import SocialMediaFeed from '../components/socialMediaFeed';

function Feed() {
  return (
    <BasePage>
      <SocialMediaFeed />
    </BasePage>
  );
}

export default Feed;

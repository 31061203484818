import React, { useState, useEffect } from 'react';

const Quiz = ({ quizData }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        setSelectedOption(null);
        setSubmitted(false);
    }, [quizData]);

    const handleOptionChange = (optionId) => {
        if (!submitted) {
            setSelectedOption(optionId);
        }
    };

    const handleSubmit = () => {
        setSubmitted(true);
    };

    const isCorrect = (optionId) => {
        return quizData.correctAnswerId === optionId;
    };

    return (
        <div className="bg-gray-100 p-4 rounded-lg mb-4 shadow-inner relative">
            <button
                onClick={handleSubmit}
                disabled={submitted}
                className="absolute top-2 right-2 bg-blue-500 text-white py-1 px-3 rounded shadow hover:bg-blue-600 transition-colors disabled:opacity-50"
            >
                Submit
            </button>
            <h2 className="font-bold text-sm mb-2">Quiz Section</h2>
            <p className="text-gray-700 mb-2">{quizData.quizQuestion}</p>
            <ul className="space-y-2">
                {quizData.quizOptions.map((option) => (
                    <li key={option.id} className="flex flex-col">
                        <div className="flex items-center">
                            <input
                                type="radio"
                                id={option.id}
                                name="quiz"
                                className="mr-2 focus:ring-blue-500"
                                onChange={() => handleOptionChange(option.id)}
                                checked={selectedOption === option.id}
                                disabled={submitted}
                            />
                            <label
                                htmlFor={option.id}
                                className={`text-gray-700 ${
                                    submitted && isCorrect(option.id)
                                        ? "text-green-600 font-bold"
                                        : submitted && selectedOption === option.id
                                        ? "text-red-600 font-bold"
                                        : ""
                                }`}
                            >
                                {option.label}
                            </label>
                        </div>
                        {submitted && (
                            <p className="text-gray-600 italic mt-1">
                                {option.explanation}
                            </p>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Quiz;